<section *ngIf="products" class="section-b-space ratio_asos">
    <div class="container">
        <div class="row card-box-margin clickable">
            <div class="col-lg-3 col-sm-12" *ngFor="let product of products">
                <div class="card-box stile-div-mt-mb border-radius-10">
                    <div class="absolute-img mb-4" style="height: 5rem">
                        <img [src]="product?.src" width="672" height="310" class="img-fluid height-img" alt="">
                    </div>
                    <div class="card-content">
                        <div class="mordern-bottom">
                            <div class="left mt-3">
                                <a>
                                    <h4 class="p-1 text-uppercase text-black">{{ product?.title | uppercase }}</h4>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="product" class="section-b-space ratio_asos">
    <div class="card-box-margin clickable">
        <div class="col-12">
            <div class="card-box card-box-single stile-div-mt border-radius-10">
                <div class="absolute-img mb-4" style="height: 5rem">
                    <img [src]="product?.src" class="img-fluid height-img" alt="">
                </div>
                <div class="card-content">
                    <div class="mordern-bottom">
                        <div class="left mt-3">
                            <h4 class="p-1 text-black">{{ product?.title | uppercase }}</h4>
                            <h3 class="p-1 color-default">{{ product?.bonus | uppercase }}</h3>
                            <h6 class="p-1 text-lowercase">{{ product?.message | uppercase }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section *ngIf="productConf" class="section-b-space ratio_asos section-slider">
        <div class="clickable" (click)="goToLink(productConf?.payload?.uri)">
                <div class="card-box card-box-single card-item stile-div-mt border-radius-10" style="display: inline-grid">
                    <div style="height: 12rem;">
                        <div class="absolute-img align-items-center d-flex justify-content-center">
                            <img ngSrc="{{productConf?.payload?.image_path?.medium}}"
                                 width="252"
                                 height="116" class="img-fluid contain-image-dim"
                                 alt="{{productConf?.payload?.image_path?.originalname}}"
                                 loading="eager" style="background-color: transparent">
                        </div>
                    </div>
                    <div class="card-content  mt-0" id="fromSlider">
                        <div class="title-space">
                            <h6 class="color-black">{{ getLabelDynamic( productConf?.payload, 'title') }}</h6>
                        </div>
                        <div class="sub-title-space" style="height: 3.5rem">
                            <h3 class="color-default mb-0">{{ productConf?.payload?.subtitle_it | uppercase }}</h3>
                            <p class="text-lowercase fs-16 text-truncate" matTooltipPosition="above"
                               [matTooltip]="getLabelDynamic(productConf?.payload, 'heading')" tooltipShowIfTruncated>
                                {{ getLabelDynamic(productConf?.payload, 'heading') }}
                            </p>
                        </div>
                    </div>
                </div>
        </div>
</section>

<section *ngIf="item" class="section-b-space ratio_asos">
    <div class="container">
        <div class="clickable" (click)="goToLink(item?.uri)">
            <div class="col-12">
                @if (checkValidityOfferTag(item)) {
                    <img class="tags-position-card" src="./assets/images/press-up/icone/tags/offerta.svg"
                         width="64px" height="64px" alt="new-icon">
                }
                @if (checkValidityPromoTag(item)) {
                    <img class="tags-position-card" src="./assets/images/press-up/icone/tags/promo.svg"
                         width="64px" height="64px" alt="new-icon">
                }
                <div class="card-box card-box-single card-item stile-div-mt border-radius-10">
                    <div style="height: 12rem;">
                        <div class="absolute-img align-items-center d-flex justify-content-center">
                            <img ngSrc="{{item?.preview_id_picture?.min ?? item?.id_picture?.min}}"
                                 placeholder width="252"
                                 height="116" class="img-fluid contain-image-dim"
                                 alt="{{item?.preview_id_picture?.originalname ?? item?.id_picture?.originalname}}"
                                 loading="eager" style="background-color: transparent">
                        </div>
                        <div class="tags-space">
                            <div class="tag-new-space" style="align-self: center">
                                @if (checkValidityTagNew(item)) {
                                    <img style="margin-left: -25px;" src="./assets/images/press-up/icone/tags/new.svg"
                                         width="78px" height="35px" alt="new-icon">
                                }
                            </div>
                            <div class="tag-space" style="align-self: center; margin-right: -1rem !important;">
                                <div class="row d-flex tags-personalized-position-card">
                                    @if (item?.tag_items?.[0]?.icn_page_path?.original) {
                                        <img [src]="getImg(item?.tag_items?.[0]?.icn_page_path?.original)" class="w-80-px"
                                             alt="new-icon">
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-content" id="fromShowcase">
                        <div class="title-space">
                            <h6 class="color-black">{{ getLabelDynamic(item, 'preview_title') }}</h6>
                        </div>
                        <div class="sub-title-space">
                            <p class="text-lowercase text-muted fs-16" #cardSubtitle matTooltipPosition="above"
                               [matTooltip]="getLabelDynamic(item, 'preview_subtitle')" appShowIfTruncateVerticalDirective>
                                {{ getLabelDynamic(item, 'preview_subtitle') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
