import {UserInfo} from '../../modules/auth/models/User';
import * as uuid from 'uuid';
import {IItemBreadcrumbApi} from '../../shared/interfaces/IItemBreadcrumbApi';
import {ElementRef} from '@angular/core';

export function getImageFromUrl(urlImage: string, BASE_URL: string): string {
    return `${BASE_URL}${urlImage}`;
}

export function clearKeys(userInfo: UserInfo): void {
    Object.keys(userInfo).forEach(k => {
        if (userInfo[k] == '') {
            userInfo[k] = null;
        }
    });
}

export function generateUIID(): string {
    return uuid.v4();
}

export function buildItemBreadcrumbByBreadcrumbApi(breadcrumbApi: Array<IItemBreadcrumbApi>) {
    return breadcrumbApi.map((item: IItemBreadcrumbApi) => ({label: item.name, path: item.uri}));
}

export function isTextTruncated(element: ElementRef): boolean {
    const e = element?.nativeElement;
    return e?.scrollWidth > e?.clientWidth;
}
