<div class="navbar">
    <a (click)="leftMenuToggleAction()" class="z-1">
        <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
    </a>
    <div id="mySidenav" class="sidenav" [class.openSide]="leftMenuToggle">
        <a class="sidebar-overlay" (click)="leftMenuToggleAction()"
           (mouseover)="onHover(false)">
        </a>
        <nav id="unset">
            <div (click)="leftMenuToggleAction()">
                <div class="sidebar-back text-start"><i class="fa fa-angle-left pe-2" aria-hidden="true"></i> Back
                </div>
            </div>
            <ul id="sub-menu" class="sidebar-menu">
                <li *ngFor="let menuItem of menuItems" (mouseover)="onHover(!!menuItem.children)">
                    <!-- Sub -->
                    <a *ngIf="menuItem.type === 'sub'" class="handle-overflow"
                       (click)="toggletNavActive(menuItem)">
                        {{ getLabel(menuItem) }}
                        <div class="new-tag" *ngIf="menuItem?.badge">{{ menuItem?.badgeText | translate }}</div>
                        <span class="sub-arrow" *ngIf="menuItem.children"></span>
                    </a>
                    <!-- Link -->
                    <a [routerLink]="menuItem.path" *ngIf="menuItem.type === 'link'" class="handle-overflow">
                        {{ getLabel(menuItem) }}
                        <div class="new-tag" *ngIf="menuItem?.badge">{{ menuItem?.badgeText | translate }}</div>
                        <span class="sub-arrow" *ngIf="menuItem.children"></span>
                    </a>
                    <!-- External Link -->
                    <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extLink'" class="handle-overflow">
                        {{ getLabel(menuItem) }}
                        <div class="new-tag" *ngIf="menuItem?.badge">{{ menuItem?.badgeText | translate }}</div>
                        <span class="sub-arrow" *ngIf="menuItem.children"></span>
                    </a>
                    <!-- External Tab Link -->
                    <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'" class="handle-overflow">
                        {{ getLabel(menuItem) }}
                        <div class="new-tag" *ngIf="menuItem?.badge">{{ menuItem?.badgeText | translate }}</div>
                        <span class="sub-arrow" *ngIf="menuItem.children"></span>
                    </a>
                    <!-- 2nd Level Menu -->
                    <ul [class]="menuItem.megaMenu ? 'mega-menu clothing-menu' : ''" [class.opensub1]="menuItem.active"
                        *ngIf="menuItem.children">

                        <!-- Simple Menu Start-->
                        <ng-container *ngIf="!menuItem.megaMenu">
                            <li *ngFor="let childrenItem of menuItem.children">
                                <!-- Link -->
                                <a class="has-submenu" [routerLink]="childrenItem.path"
                                   *ngIf="childrenItem.type === 'link'">
                                    {{ getLabel(childrenItem) }}

                                </a>
                                <!-- External Link -->
                                <a href="{{ !childrenItem.type ? null : childrenItem.path }}"
                                   *ngIf="childrenItem.type === 'extLink'">
                                    {{ getLabel(childrenItem) }}

                                </a>
                                <!-- External Tab Link -->
                                <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
                                   *ngIf="childrenItem.type === 'extTabLink'">
                                    {{ getLabel(childrenItem) }}
                                </a>
                                <!-- 3rd Level Menu -->
                                <ul *ngIf="childrenItem.children">
                                    <li *ngFor="let childrenSubItem of childrenItem.children">
                                        <!-- Link -->
                                        <a [routerLink]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'link'">
                                            {{ getLabel(childrenSubItem) }}
                                        </a>
                                        <!-- External Link -->
                                        <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'">
                                            {{ getLabel(childrenSubItem) }}
                                        </a>
                                        <!-- External Tab Link -->
                                        <a href="{{ childrenSubItem.path }}" target="_blank"
                                           *ngIf="childrenSubItem.type === 'extTabLink'">
                                            {{ getLabel(childrenSubItem) }}
                                        </a>
                                        <!-- 4th Level Menu -->
                                        <ul *ngIf="childrenSubItem.children">
                                            <li *ngFor="let childrenSubSubItem of childrenSubItem.children">
                                                <!-- Link -->
                                                <a [routerLink]="childrenSubSubItem.path"
                                                   *ngIf="childrenSubSubItem.type === 'link'">
                                                    {{ getLabel(childrenSubSubItem) }}
                                                </a>
                                                <!-- External Link -->
                                                <a href="{{ childrenSubSubItem.path }}"
                                                   *ngIf="childrenSubSubItem.type === 'extLink'">
                                                    {{ getLabel(childrenSubSubItem) }}
                                                </a>
                                                <!-- External Tab Link -->
                                                <a href="{{ childrenSubSubItem.path }}" target="_blank"
                                                   *ngIf="childrenSubSubItem.type === 'extTabLink'">
                                                    {{ getLabel(childrenSubSubItem) }}
                                                </a>
                                                <!-- 5th Level Menu -->
                                                <ul *ngIf="childrenSubSubItem.children">
                                                    <li *ngFor="let childrenSubSubSubItem of childrenSubSubItem.children">
                                                        <!-- Link -->
                                                        <a [routerLink]="childrenSubSubSubItem.path"
                                                           *ngIf="childrenSubSubSubItem.type === 'link'">
                                                            {{ getLabel(childrenSubSubSubItem) }}
                                                        </a>
                                                        <!-- External Link -->
                                                        <a href="{{ childrenSubSubSubItem.path }}"
                                                           *ngIf="childrenSubSubSubItem.type === 'extLink'">
                                                            {{ getLabel(childrenSubSubSubItem) }}
                                                        </a>
                                                        <!-- External Tab Link -->
                                                        <a href="{{ childrenSubSubSubItem.path }}" target="_blank"
                                                           *ngIf="childrenSubSubSubItem.type === 'extTabLink'">
                                                            {{ getLabel(childrenSubSubSubItem) }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ng-container>
                        <!-- Simple Menu End-->

                        <!-- Mega Menu Start-->
                        <ng-container *ngIf="menuItem.megaMenu">
                            <li>
                                <div class="row m-0">
                                    <div class="col-xl-4" *ngFor="let childrenItem of menuItem.children">
                                        <div class="link-section">
                                            <h5 class="handle-overflow"
                                                [routerLink]="childrenItem.path">{{ getLabel(childrenItem) }}</h5>
                                            <ul>
                                                <li *ngFor="let childrenSubItem of childrenItem.children">
                                                    <!-- Link -->
                                                    <a [routerLink]="childrenSubItem.path"
                                                       *ngIf="childrenSubItem.type === 'link'" class="handle-overflow">
                                                        {{ getLabel(childrenSubItem) }}
                                                        <span class="new-tag ps-2 pe-2"
                                                              *ngIf="childrenSubItem?.badge">{{ childrenSubItem?.badgeText | translate }}</span>
                                                    </a>
                                                    <!-- External Link -->
                                                    <a href="{{ childrenSubItem.path }}"
                                                       *ngIf="childrenSubItem.type === 'extLink'"
                                                       class="handle-overflow">
                                                        {{ getLabel(childrenSubItem) }}
                                                    </a>
                                                    <!-- External Tab Link -->
                                                    <a href="{{ childrenSubItem.path }}" target="_blank"
                                                       *ngIf="childrenSubItem.type === 'extTabLink'"
                                                       class="handle-overflow">
                                                        {{ getLabel(childrenSubItem) }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <!-- Mega Menu End-->
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</div>

